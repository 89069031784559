<template>
  <div class="navigation_view" v-if="someStateValueRef">
    <div class="logo_img" @click="goPath('/')">
      <img src="@/assets/img/navigationView/logo.png" />
    </div>
    <div class="logo_text">
      <!-- <div class="option_btn" v-for="item in pathList" :key="item.id">
        <div @click="goPath(item.path)">{{ item.text }}</div>
      </div> -->
      <div class="option_btn animation_convention_first">
        <div @click="goPath('/usPem')">帕尔姆网络</div>
      </div>

      <div class="my_dropdown">
        <el-dropdown>
          <div class="option_btn">
            <div>业务范围</div>
            <img src="@/assets/img/navigationView/xiala.png" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item><div>帕尔姆·云餐</div></el-dropdown-item>
              <el-dropdown-item><div>帕尔姆·云商</div></el-dropdown-item>
              <el-dropdown-item><div>帕尔姆·ERP</div></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div class="right_box">
        <!-- 切换语言的图标 -->
        <img src="@/assets/img/navigationView/language.png" />
        <!-- 咨询中心 -->
        <div class="animation_convention_second" @click="goPath('/verify')">资讯中心</div>
        <!-- 加入我们 -->
        <div class="animation_convention_third" @click="goPath('/joinUs')">加入我们</div>
        <div class="animation_convention_third" @click="goPath('/contactUs')">联系我们</div>
      </div>
    </div>

    <div class="cc_logo_text" @click="clickDrawer">
      <div class="right_box">
        <img src="@/assets/img/navigationView/Menu.png" />
      </div>
      <!-- 侧边栏 -->
      <el-drawer v-model="drawer" direction="rtl" size="100%">
        <div class="drawerBox">
          <div class="drawerTop">
            <img
              class="topImg"
              @click="clickDrawerTopImg"
              src="@/assets/img/navigationView/lets-icons_back.png"
            />
            <div class="drawerText">
              <el-collapse accordion>
                <el-collapse-item v-for="item in pathList" :name="item.id" :key="item.id">
                  <template #title>
                    <div class="drawerTextTitle" @click="goPath(item.path)">{{ item.text }}</div>
                  </template>
                  <div class="drawerTextItem" v-for="i in item.listText" :key="i">
                    {{ i }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <div class="drawerBottom">
            <div class="languageSwitch">
              <img src="@/assets/img/navigationView/language.png" />
            </div>
            <div class="drawerBtn">
              <!-- 咨询中心 -->
              <div class="animation_convention_second" @click="goPath('/verify')">咨询中心</div>
              <!-- 加入我们 -->
              <div class="animation_convention_third" @click="goPath('/joinUs')">加入我们</div>
              <div class="animation_convention_third" @click="goPath('/contactUs')">联系我们</div>
            </div>
            <div class="drawerLogo" @click="goPath('/')">
              <img src="@/assets/img/navigationView/logo.png" />
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { pathList } from './option'
import { useRouter, Router } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const someStateValueRef = computed(() => store.state.isNavigation)

const drawer = ref(false)
// 手机端打开侧边栏
const clickDrawer = () => {
  drawer.value = true
}
const clickDrawerTopImg = () => {
  drawer.value = false
}

// 路由跳转
const router: Router = useRouter()
const goPath = (path: string) => {
  router.push({ path })

  drawer.value = false
}
</script>

<style scoped lang="scss">
.navigation_view {
  display: flex;
  height: 72px;
  width: 100%;
  padding: 0px 64px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  position: relative;
  z-index: 99999;
  overflow: hidden;

  .logo_img {
    width: 181px;
    height: 28px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .cc_logo_text {
    display: none;
  }

  .logo_text {
    display: flex;
    align-items: center;
    justify-content: center;

    .option_btn {
      width: 120px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 32px;
      display: flex;
      align-items: center;
      cursor: pointer;

      div {
        color: #000;
        font-family: my65;
        font-size: 16px;
      }

      img {
        width: 24px;
        height: 24px;
        margin-left: 6px;
      }
    }

    .right_box {
      display: flex;
      align-items: center;
      margin-left: 40px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 28px;
      }

      div {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        border-radius: 8px;
        font-family: my65;
        font-size: 16px;
        box-sizing: border-box;
        cursor: pointer;
      }

      & > div:nth-child(2) {
        border: 1px solid #000;
        background: #fff;
        color: #000;
      }

      & > div:nth-child(3) {
        border: 1px solid #a2c11c;
        background: #a2c11c;
        color: #f4f4f4;
      }

      & > div:nth-child(4) {
        border: 1px solid #3c3c3c;
        background: #3c3c3c;
        color: #f4f4f4;
      }
    }
  }
  /* 手机端样式 */
  @media screen and (max-width: 768px) {
    .logo_img {
      width: 181px;
      height: 28px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logo_text {
      display: none;
    }

    .cc_logo_text {
      display: block;

      .right_box {
        width: 48px;
        height: 48px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      ::v-deep(.el-drawer__header) {
        display: none;
      }

      .drawerBox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 32px 48px 32px;
        box-sizing: border-box;

        .drawerTop {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        .topImg {
          width: 48px;
          height: 48px;
          margin-bottom: 84px;
        }

        .drawerText {
          ::v-deep(.el-collapse) {
            border-top: none;
            border-bottom: none;
          }

          ::v-deep(.el-collapse-item__header) {
            border-bottom: none;
            justify-content: flex-end;
          }

          ::v-deep(.el-collapse-item) {
            margin-bottom: 16px;
          }

          ::v-deep(.el-icon) {
            height: 2em !important;
            width: 2em !important;
          }

          ::v-deep(.el-collapse-item__arrow) {
            margin: 0;
            margin-left: 8px;
          }

          ::v-deep(.el-collapse-item__wrap) {
            border-bottom: none;
          }

          .drawerTextTitle {
            color: #000;
            font-family: my85;
            font-size: 24px;
          }

          .drawerTextItem {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: #000;
            font-family: my95;
            font-size: 18px;
            padding: 10px 0;
          }
        }

        .drawerBottom {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .languageSwitch {
            width: 24px;
            height: 24px;
            margin-bottom: 24px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .drawerBtn {
            display: flex;
            flex-direction: column;
            align-items: center;

            div {
              display: flex;
              padding: 12px 24px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              font-family: my65;
              font-size: 16px;
              box-sizing: border-box;
            }

            & > div:nth-child(1) {
              border: 1px solid #000;
              background: #fff;
              color: #000;
              margin-bottom: 12px;
            }

            & > div:nth-child(2) {
              border: 1px solid #a2c11c;
              background: #a2c11c;
              color: #f4f4f4;
              margin-bottom: 12px;
            }

            & > div:nth-child(3) {
              border: 1px solid #3c3c3c;
              background: #3c3c3c;
              color: #f4f4f4;
              margin-bottom: 32px;
            }
          }

          .drawerLogo {
            width: 181.454px;
            height: 28px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navigation_view {
    height: 72px;
    padding: 0px 32px;
    /* padding: 0px 64px; */
  }
}
</style>
