import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'

// 导入组件
const home = () => import('@/views/home/index.vue')
const joinUs = () => import('@/views/joinUs/index.vue')
const verify = () => import('@/views/verify/index.vue')
const articleDetails = () => import('@/views/articleDetails/index.vue')
const jobDetails = () => import('@/views/jobDetails/index.vue')
const usPem = () => import('@/views/usPem/index.vue')
const contactUs = () => import('@/views/contactUs/index.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: joinUs
  },
  {
    path: '/verify',
    name: 'verify',
    component: verify
  },
  {
    path: '/articleDetails',
    name: 'articleDetails',
    component: articleDetails
  },
  {
    path: '/jobDetails',
    name: 'jobDetails',
    component: jobDetails
  },
  {
    path: '/usPem',
    name: 'usPem',
    component: usPem
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: contactUs
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
