import { createStore } from 'vuex'

export default createStore({
  state: {
    isNavigation: true
  },
  getters: {},
  mutations: {
    mutationFunction(state, data) {
      state.isNavigation = data
    }
  },
  actions: {},
  modules: {}
})
