<template>
  <div class="app_view">
    <navigation-view />
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import navigationView from '@/components/navigation/navigationView.vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const goTo = (item) => {
  router.push('/' + item)
}
</script>

<style lang="scss">
@import url('@/assets/css/index.scss');
</style>
