import { MenuItem } from './interface'

const pathList: MenuItem[] = [
  {
    id: 0,
    text: '关于帕尔姆网络',
    path: '/usPem',
    listText: ['产品列表', '解决方案', '客户案例', '登录']
  },
  {
    id: 1,
    text: '帕尔姆·云餐',
    path: '',
    listText: ['产品列表', '解决方案', '客户案例', '登录']
  },
  {
    id: 2,
    text: '帕尔姆·云商',
    path: '',
    listText: ['产品列表', '解决方案', '客户案例', '登录']
  },
  {
    id: 3,
    text: '帕尔姆·ERP',
    path: '',
    listText: ['产品列表', '解决方案', '客户案例', '登录']
  }
]

export { pathList }
